import { IKContext, IKImage, IKVideo } from 'imagekitio-react';
import { ChevronLeft, ChevronRight, Fullscreen } from 'lucide-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactImageGallery from 'react-image-gallery';
import PhotoAlbum from 'react-photo-album';

import { Button, IconClose, ResponsiveImage } from '../../../components';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';

import './image-gallery.css';

import css from './ListingImageGallery.module.css';

const IMAGE_KIT_ENDPOINT = process.env.REACT_APP_IMAGE_KIT_ENDPOINT;

const IMAGE_BACKGROUND = 'f4f1e9';
const IMAGE_RADIUS = 12;
const MAX_DESKTOP_CONTAINER_WIDTH = 1440;
const MAX_DESKTOP_CONTAINER_HEIGHT = 600;
const MAX_DESKTOP_LIGHTBOX_IMAGE_WIDTH = 3840;
const MAX_DESKTOP_LIGHTBOX_VIDEO_WIDTH = 1920;
const MAX_MOBILE_SCREEN_WIDTH = 1024;
const MAX_MOBILE_LIGHTBOX_IMAGE_WIDTH = 2560;
const MAX_MOBILE_LIGHTBOX_VIDEO_WIDTH = 1280;
const MAX_MEDIA_COUNT = 3;
const MOBILE_ASPECT_RATIO = 4 / 3;

const IMAGE_GALLERY_OPTIONS = {
  showPlayButton: false,
  disableThumbnailScroll: true,
};

const lqip = { active: true, quality: 20 };

const ListingImageGallery = props => {
  const { intl, images } = props;

  if (!images || images.length === 0) {
    return <ResponsiveImage className={css.noImage} image={null} variants={[]} alt="" />;
  }

  const photos = images
    ? images.map((img, i) => {
        if (!img.ik) {
          return {};
        }

        const isVideo = img.ik.fileType === 'non-image' && 'videoCodec' in img.ik;

        // NOTE: The ik-video.mp4 ending prevents IKVideo's transformation prop from working
        const path = isVideo ? `${img.ik.filePath}/ik-video.mp4` : img.ik.filePath;

        return {
          src: img.ik.url,
          height: img.ik.height,
          width: img.ik.width,
          path,
          isVideo,
          type: isVideo ? 'video' : 'image',
          image: img,
          index: i,
          sources: [
            {
              src: IMAGE_KIT_ENDPOINT + path,
              type: 'video/mp4',
            },
          ],
          poster: IMAGE_KIT_ENDPOINT + path + '/ik-thumbnail.jpg',
          thumbnail: IMAGE_KIT_ENDPOINT + path + (isVideo ? '/ik-thumbnail.jpg' : '?tr=w-100'),
          thumbAlt: intl.formatMessage(
            { id: 'ListingImageGallery.imageThumbnailAltText' },
            { index: i + 1, count: images.length }
          ),
        };
      })
    : [];

  const imageGalleryRef = React.createRef();
  const [isFullscreen, setIsFullscreen] = useState(false);

  const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
  const isMobileLayout = hasMatchMedia
    ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
    : true;

  const renderItemMobile = item => {
    const mobileHeight = MAX_MOBILE_SCREEN_WIDTH / MOBILE_ASPECT_RATIO;

    let targetRadius = IMAGE_RADIUS;
    let targetImageWidth = MAX_MOBILE_SCREEN_WIDTH;
    let targetVideoWidth = MAX_MOBILE_SCREEN_WIDTH;
    let targetImageHeight = mobileHeight;
    let targetVideoHeight = mobileHeight;
    let videoTransformation = `?tr=w-${targetVideoWidth},h-${targetVideoHeight},bg-${IMAGE_BACKGROUND},r-${targetRadius}`;

    if (isFullscreen) {
      targetImageWidth = Math.min(item.width, MAX_MOBILE_LIGHTBOX_IMAGE_WIDTH);
      targetVideoWidth = Math.min(item.width, MAX_MOBILE_LIGHTBOX_VIDEO_WIDTH);
      targetImageHeight = targetImageWidth / (item.width / item.height);
      targetVideoHeight = targetVideoWidth / (item.width / item.height);
      videoTransformation = `?tr=w-${targetVideoWidth},c-maintain_ratio`;
      targetRadius = 0;
    }

    return (
      <div className={css.itemCentering}>
        <IKContext urlEndpoint={IMAGE_KIT_ENDPOINT}>
          {item.isVideo ? (
            <IKVideo
              className={isFullscreen ? css.fsMedia : css.lbVideo}
              disablePictureInPicture
              loop
              muted
              playsInline
              autoPlay
              width={targetVideoWidth}
              height={targetVideoHeight}
              path={item.path + videoTransformation}
              poster={item.poster + videoTransformation}
            />
          ) : (
            <IKImage
              className={isFullscreen ? css.fsMedia : css.lbImage}
              lqip={lqip}
              path={item.path}
              width={targetImageWidth}
              height={targetImageHeight}
              transformation={[
                {
                  height: targetImageHeight,
                  width: targetImageWidth,
                  background: IMAGE_BACKGROUND,
                  radius: targetRadius,
                },
              ]}
            />
          )}
        </IKContext>
      </div>
    );
  };

  const renderItemDesktop = item => {
    let targetImageHeight = MAX_DESKTOP_CONTAINER_HEIGHT;
    let targetVideoHeight = MAX_DESKTOP_CONTAINER_HEIGHT;
    let targetImageWidth = targetImageHeight * (item.width / item.height);
    let targetVideoWidth = targetVideoHeight * (item.width / item.height);
    let videoTransformation = `?tr=w-${targetVideoWidth},h-${targetVideoHeight},c-maintain_ratio`;

    if (isFullscreen) {
      targetImageWidth = Math.min(item.width, MAX_DESKTOP_LIGHTBOX_IMAGE_WIDTH);
      targetVideoWidth = Math.min(item.width, MAX_DESKTOP_LIGHTBOX_VIDEO_WIDTH);
      targetImageHeight = targetImageWidth / (item.width / item.height);
      targetVideoHeight = targetVideoWidth / (item.width / item.height);
      videoTransformation = `?tr=w-${targetVideoWidth},h-${targetVideoHeight},c-maintain_ratio`;
    }

    return (
      <div className={css.itemCentering}>
        <IKContext urlEndpoint={IMAGE_KIT_ENDPOINT}>
          {item.isVideo ? (
            <IKVideo
              className={isFullscreen ? css.fsMedia : css.lbVideo}
              disablePictureInPicture
              loop
              muted
              playsInline
              autoPlay
              width={targetVideoWidth}
              height={targetVideoHeight}
              path={item.path + videoTransformation}
              poster={item.poster + videoTransformation}
            />
          ) : (
            <IKImage
              className={isFullscreen ? css.fsMedia : css.lbImage}
              lqip={lqip}
              path={item.path}
              width={targetImageWidth}
              height={targetImageHeight}
              transformation={[
                {
                  width: targetImageWidth,
                  height: targetImageHeight,
                  crop: 'maintain_ratio',
                },
              ]}
            />
          )}
        </IKContext>
      </div>
    );
  };

  const onScreenChange = isFull => {
    if (!isFull && !isMobileLayout) {
      imageGalleryRef.current.slideToIndex(0);
    }

    setIsFullscreen(isFull);
  };

  const onThumbnailClick = (event, index) => {
    imageGalleryRef.current.slideToIndex(index);

    if (!isFullscreen) {
      imageGalleryRef.current.toggleFullScreen();
    }
  };

  const renderLeftNav = (onClick, disabled) => {
    return (
      <button className={css.navLeft} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <ChevronLeft />
        </div>
      </button>
    );
  };
  const renderRightNav = (onClick, disabled) => {
    return (
      <button className={css.navRight} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <ChevronRight />
        </div>
      </button>
    );
  };
  const renderFullscreenButton = (onClick, isFullscreen) => {
    return isFullscreen ? (
      <Button
        onClick={onClick}
        rootClassName={css.close}
        title={intl.formatMessage({ id: 'ListingImageGallery.closeModalTitle' })}
      >
        <IconClose rootClassName={css.closeIcon} />
      </Button>
    ) : (
      <button className={css.openFullscreen} onClick={onClick}>
        <Fullscreen />
      </button>
    );
  };

  const showDesktopCover =
    photos[0].width > photos[0].height && photos[0].width < 2 * photos[0].height;

  return (
    <>
      {isMobileLayout ? (
        <div>
          <ReactImageGallery
            ref={imageGalleryRef}
            items={photos}
            renderItem={renderItemMobile}
            onScreenChange={onScreenChange}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
            renderFullscreenButton={renderFullscreenButton}
            showFullscreenButton={true}
            showIndex={!isFullscreen}
            showNav={isFullscreen}
            showThumbnails={false}
            {...IMAGE_GALLERY_OPTIONS}
          />
        </div>
      ) : (
        <IKContext urlEndpoint={IMAGE_KIT_ENDPOINT}>
          <div className={css.desktopGallery}>
            <div
              className={css.desktopGalleryContainer}
              style={{
                aspectRatio: `${photos[0].width} / ${photos[0].height}`,
                maxHeight: photos.length === 1 && '100%',
              }}
            >
              <ReactImageGallery
                ref={imageGalleryRef}
                items={photos}
                renderItem={renderItemDesktop}
                onScreenChange={onScreenChange}
                renderLeftNav={renderLeftNav}
                renderRightNav={renderRightNav}
                renderFullscreenButton={renderFullscreenButton}
                showFullscreenButton={true}
                showIndex={false}
                showNav={isFullscreen}
                showThumbnails={false}
                {...IMAGE_GALLERY_OPTIONS}
              />
            </div>
            {photos.length > 1 && (
              <div
                className={css.desktopAlbum}
                style={{
                  width: photos.length === 1 ? 'auto' : '100%',
                }}
              >
                <PhotoAlbum
                  layout={'rows'}
                  spacing={12}
                  photos={photos.slice(1, showDesktopCover ? photos.length : MAX_MEDIA_COUNT)}
                  rowConstraints={{ maxPhotos: showDesktopCover ? 1 : MAX_MEDIA_COUNT }}
                  renderPhoto={({ photo, wrapperStyle }) => (
                    <div style={wrapperStyle} onClick={() => onThumbnailClick(null, photo.index)}>
                      {photo.isVideo ? (
                        <IKVideo
                          disablePictureInPicture
                          loop
                          muted
                          playsInline
                          autoPlay
                          className={css.ikVideo}
                          path={`${
                            photo.path
                          }?tr=w-${MAX_DESKTOP_CONTAINER_WIDTH},h-${MAX_DESKTOP_CONTAINER_WIDTH /
                            (photo.width / photo.height)}`}
                          poster={`${
                            photo.poster
                          }?tr=w-${MAX_DESKTOP_CONTAINER_WIDTH},h-${MAX_DESKTOP_CONTAINER_WIDTH /
                            (photo.width / photo.height)}`}
                        />
                      ) : (
                        <IKImage
                          className={css.ikImage}
                          path={photo.path}
                          lqip={lqip}
                          width={MAX_DESKTOP_CONTAINER_HEIGHT * (photo.width / photo.height)}
                          height={MAX_DESKTOP_CONTAINER_HEIGHT}
                          transformation={[
                            {
                              height: MAX_DESKTOP_CONTAINER_HEIGHT,
                              width: MAX_DESKTOP_CONTAINER_HEIGHT * (photo.width / photo.height),
                            },
                          ]}
                        />
                      )}
                    </div>
                  )}
                />
              </div>
            )}
          </div>
        </IKContext>
      )}
    </>
  );
};

ListingImageGallery.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string, arrayOf, oneOfType } = PropTypes;

ListingImageGallery.propTypes = {
  rootClassName: string,
  className: string,
  images: arrayOf(oneOfType([propTypes.image, propTypes.imageAsset, propTypes.imageKitMedia])),

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(ListingImageGallery);
