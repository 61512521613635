import React from 'react';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';

import css from './ListingPage.module.css';

const SectionGallery = props => {
  const { listing, variantPrefix, isProfile = false } = props;
  let images = [];

  if (isProfile) {
    images = listing.attributes.profile.publicData.media;
  } else {
    images = listing.attributes.publicData.media;
  }

  const hasWindow = typeof window !== 'undefined';

  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  return (
    <section className={css.productGallery} data-testid="carousel">
      {hasWindow && <ListingImageGallery images={images} />}
    </section>
  );
};

export default SectionGallery;
