import React from 'react';
import css from './ProviderSocialShareCallout.module.css';
import { ArrowRight } from 'lucide-react';
import { NamedLink, SecondaryButton } from '../../../components';

const ProviderSocialShareCallout = ({ intl, profileOrListingId }) => {
  return (
    <div className={css.container}>
      <img
        src="https://ik.imagekit.io/iry7rmaup/page-images/social-share-hand-holding-phone.png?updatedAt=1725039454317&tr=h-400"
        alt="Hand holding phone"
        className={css.phoneImage}
      />
      <div className={css.textSection}>
        <h1 className={css.title}>
          {intl.formatMessage({ id: 'ProviderShareModal.socialPostBuilder.title' })}
        </h1>
        <p className={css.subtitle}>
          {intl.formatMessage({ id: 'ProviderShareModal.socialPostBuilder.description' })}
        </p>
        <NamedLink
          className={css.namedLink}
          name={'SocialSharePage'}
          to={{ search: `id=${profileOrListingId}` }}
        >
          <SecondaryButton className={css.button}>
            {intl.formatMessage({ id: 'ProviderShareModal.socialPostBuilder.ctaLabel' })}
            <ArrowRight className={css.buttonIcon} />
          </SecondaryButton>
        </NamedLink>
      </div>
    </div>
  );
};

export default ProviderSocialShareCallout;
